import ApiService from './ApiService';

export async function csrf() {
    return await ApiService.fetchData({
        url: '/sanctum/csrf-cookie',
        method: 'get',
    });
}

export async function apiSignIn(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data,
    });
}

export async function apiSignUp(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data,
    });
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/logout',
        method: 'post',
        data,
    });
}

export async function apiForgotPassword(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    });
}

export async function apiResetPassword(data) {
    await csrf();

    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    });
}

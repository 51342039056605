import ConfigProvider, {
    ConfigContext,
    ConfigConsumer,
    useConfig,
    defaultConfig,
} from './ConfigProvider';

export {ConfigContext, ConfigConsumer, useConfig, defaultConfig};

export default ConfigProvider;

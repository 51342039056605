import React from 'react';
// import { useConfig } from '../ConfigProvider'
import {FaSort, FaSortUp, FaSortDown} from 'react-icons/fa';

const Sorter = ({sort}) => {
    // const { themeColor, primaryColorLevel } = useConfig()

    // const color = `text-${themeColor}-${primaryColorLevel}`

    const renderSort = () => {
        if (sort === 'asc') {
            return <FaSortUp />;
        } else if (sort === 'desc') {
            return <FaSortDown />;
        } else {
            return <FaSort />;
        }
    };

    return <div className="inline-flex">{renderSort()}</div>;
};

export default Sorter;
